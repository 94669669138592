import React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../pages/work.scss'


const ProjectPage = ({data}) => (
  <Layout>
    <SEO title="Work" />
		<section className='projects-feed'>
			{/* <h1 className='projects-feed__title'>All Projects</h1> */}
			<ol className='projects-list'>
			{data.allMarkdownRemark.edges.map(project => (
				<li className='projects-list__item' key={ project.node.id }>
					<article>
						<Link className='projects-list__item-inner' to={project.node.frontmatter.path}>
							{/* <h3 className='projects-list__item-title'>{project.node.frontmatter.title}</h3> */}
							<figure className='projects-list__item-img-contain'>
								<img className='projects-list__item-img' src={project.node.frontmatter.image} alt={project.node.frontmatter.imageAlt} />
								<figcaption className='projects-list__item-title'>{project.node.frontmatter.title}</figcaption>
							</figure>
						</Link>
					</article>
				</li>
			))}
		</ol>
		</section>
  </Layout>
)

export const pageQuery = graphql`
	query ProjectIndexQuery {
		allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___date] },
            filter: { frontmatter: { type: { eq: "project" } } }
        ) {
			edges {
				node {
					id
					frontmatter {
						path
						title
						image
						imageAlt
					}
				}
			}
		}
	}
`

export default ProjectPage
